export default {
  map: {
    addMarker: "Click to add a point",
    moveMarker: "Click and drag to move",
  },
  title: "Position",
  instructions:
    "Specify 'Asset Position’ and its 'Access Point’ by entering the geographical coordinates of each or define it directly on the map.",
  assetPosition: "Asset Position",
  accessPoint: "Access Point",
  latitude: "Latitude",
  longitude: "Longitude",
  zone: "Zone",
  east: "East",
  north: "North",
  checkboxLabel: "Confirm route adjustment",
  checkboxDescription:
    "Please check this box to confirm that you have modified the line connecting the two points on the map to accurately represent the real route from the access point to the asset.",
};
