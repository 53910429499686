export default {
  header: "Warnung",
  dataloss_1:
    "Sie sind im Begriff diese Seite zu verlassen. Ihre Eingaben oder Änderungen während dieser Sitzung werden nicht gespeichert. Möchten Sie diese Seite wirklich verlassen? Nein (zurück zur Eingabe) / Ja (Seite verlassen)",
  changedata:
    "Bitte bestätigen Sie, dass Sie berechtigt sind die Daten manuell zu ändern und dass die neuen Daten korrekt sind.",
  changeobject:
    "Diese(r) <name of object> wurde zuletzt vom Benutzer <mail-address of user having last updated the object>  bearbeitet (<timestamp>). Bitte bestätigen Sie, dass Sie berechtigt sind die Reservierung zu ändern",
  blockobject:
    "Diese(r) <name of object> wird gerade vom Benutzer <mail-address of user blocking the object> bearbeitet und kann derzeit nicht geändert werden. Bitte versuchen Sie es in 15 Minuten erneut oder bitten Sie den anderen Benutzer die Bearbeitung abzubrechen",
  dataloss_2: "Möchten Sie wirklich löschen?",
  dataloss_2_header: "Datensatz wird gelöscht!",
  confirmDeleteGroup: "Wollen Sie diese Gruppe wirklich löschen?",
  confirmDeleteRule: "Wollen Sie diese Regel wirklich löschen?",
  confirmImportTitle: "Neues Formular laden?",
  confirmLeaveForm: "Wollen Sie die Seite wirklich verlassen? Mögliche Änderungen werden nicht gespeichert.",
  confirmLeaveFormTitle: "Seite verlassen?",
  errorTypeFile: "Ununterstützter Dateityp!",
  changesSavedTitle: "Änderungen gespeichert",
  changesSavedMessage: "Ihre Änderungen wurden erfolgreich gespeichert.",
  confirmLogout: "Möchten Sie sich wirklich abmelden?",
  logoutTitle: "Abmelden?",
};
