// eslint-disable-next-line filenames/match-exported, import/no-extraneous-dependencies
import { merge } from "ts-deepmerge";
import projectColors from "./projectColors";
import { PaletteMode, PaletteOptions } from "@mui/material";
import { Functional, Primary, ProjectColors, Spare, StatusAndTag } from "./types";
type CustomTheme = Partial<ProjectColors> & PaletteOptions;

const paletteLight = merge(projectColors, {
  mode: "light" as PaletteMode,
  functional: {} as Functional,
  primary: {} as PaletteOptions["primary"] & Primary,
  spare: {} as Spare,
  statusAndTag: {} as StatusAndTag,

  projectColors,
} as CustomTheme) as PaletteOptions;

export default paletteLight;
