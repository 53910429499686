export default {
  label: "Billing Address",
  name: "Name/Company",
  address: "Address",
  PONumber: "PO Number",
  note: "Note",
  requiredField: "This field is required.",
  postalCodeErrorMessage: "Please enter a valid postal code consisting of only digits.",
  deleteBillingAddress: "Delete Billing Address",
  deleteBillingAddressMessage: "Do you really want to delete this billing address?",
  deleteBillingAddressesNotPossible:
    "Deleting the billing address is not possible as one of the addresses is associated with an asset.",
  deleteBillingAddressNotPossible:
    "It is not possible to delete the billing address as the invoice is associated with an asset.",
};
