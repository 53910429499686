export default {
  operator: "Operator",
  project: "Project",
  identificationNumber: "Identification Number",
  selectField: "Select Field",
  selectOperator: "Select operator",
  rule: "Rule",
  ruleSet: "Rule Set",
  not: "Not",
  selectValueSource: "Select value source",
  errorGroupQuery: "Could not get results as there is a problem with the query.",
  updateQueryMessage: "Please update group query rules.",
  emptyGroupNameMessage: "Group name cannot be empty.",
};
