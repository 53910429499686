// eslint-disable-next-line filenames/match-exported
import { CssBaseline, DrawerProps, ListItem, ListItemIcon } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DrawerFooter from "../DrawerFooter/DrawerFooter";
import { CustomList, DrawerHeader, DrawerLogoSC, DrawerStyle, OverFlowDrawer } from "./DrawerStyle";
import LogoFGWSmall from "components/icons/iconComponents/LogoFGWSmall";
import { translations } from "translations";
import useIsAuthenticated from "useIsAuthenticated";
import { Route } from "routes";
import routes from "routes/routes";

export interface NavigatorProps extends DrawerProps {
  setTitle: (props: string) => void;
  open: boolean;
  handleDrawer: (value: boolean) => void;
}

function getIsRouteSelected({ route, location }: { route: Route; location: string }): boolean {
  switch (location) {
    case "/":
      return route.label === translations.pages.assetManagement.label;
    case "/configurator":
      return route.label === translations.pages.configurator.label;
    default:
      return (
        (`/${location.split("/")[1]}` === route.path && route.label === translations.pages.assetManagement.label) ||
        location === route.path
      );
  }
}

const MiniDrawer: React.FC<NavigatorProps> = ({ setTitle, open, handleDrawer }) => {
  const location = useLocation().pathname;
  const [subNavOpen, setSubNavOpen] = useState<boolean>(false);
  const isAuthenticated = useIsAuthenticated();
  // const getChildRoutes = (routes: Route[]) => {
  //   const subRoutes = routes.reduce((acc: Route[], route: Route) => {
  //     if (route.children) {
  //       return [...acc, ...route.children];
  //     }
  //     return acc.concat;
  //   }, []);
  // };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <OverFlowDrawer variant="permanent" open={open}>
        <DrawerHeader>
          <DrawerLogoSC>
            <LogoFGWSmall />
          </DrawerLogoSC>
        </DrawerHeader>
        <CustomList>
          {routes(isAuthenticated).map((route) =>
            route.children
              ?.filter((route) => route.menuItem)
              .map((childRoute) => {
                const isActive = getIsRouteSelected({ route: childRoute, location });
                return (
                  <div
                    key={childRoute?.id}
                    onMouseLeave={() => {
                      if (subNavOpen) setSubNavOpen(false);
                    }}
                  >
                    <Link key={childRoute.path} to={childRoute.path} style={DrawerStyle.link}>
                      <ListItem
                        sx={DrawerStyle.listItem}
                        key={childRoute.path}
                        onClick={() => {
                          setTitle(childRoute.label);
                        }}
                        // onMouseEnter={() => {
                        //   if (childRoute.children !== undefined) {
                        //     setSubNavOpen(true);
                        //   } else if (subNavOpen) setSubNavOpen(false);
                        // }}
                      >
                        <ListItemIcon
                          sx={(theme) => ({
                            ...DrawerStyle.listIcon,
                            isActive,
                          })}
                        >
                          {childRoute.menuIcon ? childRoute.menuIcon({ isActive }) : null}
                        </ListItemIcon>
                      </ListItem>
                    </Link>
                  </div>
                );
              })
          )}
        </CustomList>
        <Box sx={DrawerStyle.drawerFooter}>
          <DrawerFooter open={open} />
        </Box>
      </OverFlowDrawer>
    </Box>
  );
};

export default MiniDrawer;
