import main from "./main/de";
import terms from "./terms/de";
import validationMessages from "./validationMessages/de";
import helpText from "./helpText/de";
import button from "./button/de";
import toastMessages from "./toastMessages/de";
import popupMessages from "./popupMessages/de";
import notifications from "./notifications/de";
import placeholders from "./placeholders/de";
import months from "./months/de";
import shortTerms from "./shortTerms/de";
import headlines from "./headlines/de";
import pluralTerms from "./pluralTerms/de";
import table from "./table/de";
import time from "./time/de";
import technicalData from "./technicalData/de";
import editAssetLocation from "./editAssetLocation/de";
import assetBridgeSafetyNotes from "./assetBridgeSafetyNotes/de";

export default {
  main,
  terms,
  validationMessages,
  helpText,
  button,
  toastMessages,
  popupMessages,
  notifications,
  placeholders,
  months,
  shortTerms,
  headlines,
  pluralTerms,
  table,
  time,
  technicalData,
  editAssetLocation,
  assetBridgeSafetyNotes,
};
