
        import { TypePolicies } from "@apollo/client";
        const typePolicies:TypePolicies ={
          Query: {
            fields: {
              assetAttributeBridgeAssetAttributeSet: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetAttributeBridgeAssetAttributeSet',
                      id: args.id,
                    });
                  }
                },
              assetAttribute: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetAttribute',
                      id: args.id,
                    });
                  }
                },
              assetAttributeDataType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetAttributeDataType',
                      id: args.id,
                    });
                  }
                },
              assetAttributeModelDefaultValue: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetAttributeModelDefaultValue',
                      id: args.id,
                    });
                  }
                },
              assetAttributeSet: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetAttributeSet',
                      id: args.id,
                    });
                  }
                },
              assetAttributeUnit: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetAttributeUnit',
                      id: args.id,
                    });
                  }
                },
              assetAttributeValue: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetAttributeValue',
                      id: args.id,
                    });
                  }
                },
              assetBridgeAssetTag: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetBridgeAssetTag',
                      id: args.id,
                    });
                  }
                },
              assetBridgeDevice: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetBridgeDevice',
                      id: args.id,
                    });
                  }
                },
              assetBridgeDirectory: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetBridgeDirectory',
                      id: args.id,
                    });
                  }
                },
              assetBridgeSafetyNote: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetBridgeSafetyNote',
                      id: args.id,
                    });
                  }
                },
              asset: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Asset',
                      id: args.id,
                    });
                  }
                },
              assetManufacturer: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetManufacturer',
                      id: args.id,
                    });
                  }
                },
              assetModelBridgeAssetAttributeSet: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetModelBridgeAssetAttributeSet',
                      id: args.id,
                    });
                  }
                },
              assetModel: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetModel',
                      id: args.id,
                    });
                  }
                },
              assetTag: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetTag',
                      id: args.id,
                    });
                  }
                },
              assetTypeBridgeDeviceType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetTypeBridgeDeviceType',
                      id: args.id,
                    });
                  }
                },
              assetType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'AssetType',
                      id: args.id,
                    });
                  }
                },
              attachment: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Attachment',
                      id: args.id,
                    });
                  }
                },
              billingAddress: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'BillingAddress',
                      id: args.id,
                    });
                  }
                },
              comment: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Comment',
                      id: args.id,
                    });
                  }
                },
              directory: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Directory',
                      id: args.id,
                    });
                  }
                },
              geofence: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Geofence',
                      id: args.id,
                    });
                  }
                },
              geofenceType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'GeofenceType',
                      id: args.id,
                    });
                  }
                },
              group: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Group',
                      id: args.id,
                    });
                  }
                },
              groupEntity: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'GroupEntity',
                      id: args.id,
                    });
                  }
                },
              location: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Location',
                      id: args.id,
                    });
                  }
                },
              locationType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'LocationType',
                      id: args.id,
                    });
                  }
                },
              parentAssetTypeBridgeChildType: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'ParentAssetTypeBridgeChildType',
                      id: args.id,
                    });
                  }
                },
              project: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Project',
                      id: args.id,
                    });
                  }
                },
              roleBridgeScope: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'RoleBridgeScope',
                      id: args.id,
                    });
                  }
                },
              role: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Role',
                      id: args.id,
                    });
                  }
                },
              route: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Route',
                      id: args.id,
                    });
                  }
                },
              safetyNote: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'SafetyNote',
                      id: args.id,
                    });
                  }
                },
              tenant: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'Tenant',
                      id: args.id,
                    });
                  }
                },
              userBridgeRole: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'UserBridgeRole',
                      id: args.id,
                    });
                  }
                },
              userBridgeTenant: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'UserBridgeTenant',
                      id: args.id,
                    });
                  }
                },
              user: {
                  read(_ ,{ args, toReference }){
                  if (!args) return;
                    return toReference({
                      __typename: 'User',
                      id: args.id,
                    });
                  }
                },
          }
        }
      }
      export default typePolicies
      