import { useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import paletteLight from "./PaletteLight";
import { useRecoilState } from "recoil";
import { muiThemeMode } from "themes/atom";
import paletteDark from "./PaletteDark";
import { deDE } from "@mui/material/locale";
import { deDE as dedDate } from "@mui/x-date-pickers/locales";
// eslint-disable-next-line import/no-extraneous-dependencies
import { deDE as dedeGrid } from "@mui/x-data-grid/locales";

const commonTheme = {
  typography: { fontFamily: "Roboto", fontSize: 16 },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1500,
    },
  },
};

const lightTheme = createTheme(
  {
    ...commonTheme,
    palette: paletteLight,
  },
  dedeGrid,
  dedDate,
  deDE
);
const darkTheme = createTheme(
  {
    ...commonTheme,
    palette: paletteDark,
  },
  dedeGrid,
  dedDate,
  deDE
);

export const useCustomThemeManager = () => {
  const [mode, setMode] = useRecoilState<string>(muiThemeMode);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [setMode]
  );

  const theme = mode === "light" ? lightTheme : darkTheme;

  return { colorMode, theme, mode };
};

export default () => {
  const { theme } = useCustomThemeManager();
  return theme;
};
