export default {
  addGeoFence: {
    label: "Add New Location",
  },
  general: "General",
  geofencesTable: {
    label: "Locations Overview",
  },
  label: "Fleet Inventory",
  map: "Map",
  movement: "Movement",
  movementHistory: "Movement History",
  multiSelectLabel: "Location",
  noPolygonWarning: "Location must be selected",
  technicalDetails: "Technical Details",
};
