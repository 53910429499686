import * as React from "react";
import { MenuIconProps } from "routes/routes";
import projectColors, { namelessColor } from "themes/projectColors";

const MapIcon = (props: MenuIconProps & JSX.IntrinsicAttributes) => {
  if (props.isActive)
    return (
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
          x="0.5"
          y="0.5"
          width="37"
          height="37"
          rx="7.5"
          fill={projectColors.functional.navBarMenuIconsBackground}
          stroke={projectColors.primary.main}
        />
        <path
          d="M27.5 10L27.34 10.03L22 12.1L16 10L10.36 11.9C10.15 11.97 10 12.15 10 12.38V27.5C10 27.78 10.22 28 10.5 28L10.66 27.97L16 25.9L22 28L27.64 26.1C27.85 26.03 28 25.85 28 25.62V10.5C28 10.22 27.78 10 27.5 10ZM17 12.47L21 13.87V25.53L17 24.13V12.47ZM12 13.46L15 12.45V24.15L12 25.31V13.46ZM26 24.54L23 25.55V13.86L26 12.7V24.54Z"
          fill={projectColors.primary.main}
        />
      </svg>
    );
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="38" height="38" rx="8" fill={projectColors.functional.white} />
      <path
        d="M27.5 10L27.34 10.03L22 12.1L16 10L10.36 11.9C10.15 11.97 10 12.15 10 12.38V27.5C10 27.78 10.22 28 10.5 28L10.66 27.97L16 25.9L22 28L27.64 26.1C27.85 26.03 28 25.85 28 25.62V10.5C28 10.22 27.78 10 27.5 10ZM17 12.47L21 13.87V25.53L17 24.13V12.47ZM12 13.46L15 12.45V24.15L12 25.31V13.46ZM26 24.54L23 25.55V13.86L26 12.7V24.54Z"
        fill={namelessColor[4]}
        fill-opacity="0.54"
      />
    </svg>
  );
};
export default MapIcon;
