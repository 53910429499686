import { Avatar, Box, Typography } from "@mui/material";
import LogoutButton from "authentication/LogoutButton";
import React from "react";
import projectColors from "themes/projectColors";
import useIsAuthenticated from "useIsAuthenticated";
import { DrawerFooterStyle } from "./DrawerFooterStyle";
import { getInitials } from "authentication";
import { useNavigate } from "react-router-dom";
interface Props {
  open: boolean;
}

const DrawerFooter: React.FC<Props> = ({ open }) => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  if (!isAuthenticated) return null;
  const initials = getInitials();
  return (
    <Box sx={DrawerFooterStyle.container}>
      <img
        src="/images/dbuLogo.png"
        alt="image"
        style={{ height: "60px", width: "60px", margin: "8px -8px 0px -8px" }}
      />
      <LogoutButton />
      <Avatar
        sx={{
          bgcolor: projectColors.spare.spare2,
          color: projectColors.primary.main,
          cursor: "pointer",
        }}
        onClick={() => navigate("/myProfile")}
      >
        <Typography variant="body1">{initials}</Typography>
      </Avatar>
    </Box>
  );
};

export default DrawerFooter;
