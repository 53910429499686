export default {
  map: {
    addMarker: "Klicken um einen Punkt zu setzen",
    moveMarker: "Klicken und ziehen um zu verschieben",
  },
  title: "Position",
  instructions:
    "Geben Sie die geografischen Koordinaten des 'Anlagenstandort' und des 'Zugangspunkt' ein oder definieren Sie diese direkt auf der Karte.",
  assetPosition: "Anlagenstandort",
  accessPoint: "Zuwegungspunkt",
  latitude: "Breitengrad",
  longitude: "Längengrad",
  zone: "Zone",
  east: "Ost",
  north: "Nord",
  checkboxLabel: "Bestätigen Sie die Anpassung der Route",
  checkboxDescription:
    "Bitte aktivieren Sie dieses Kästchen, um zu bestätigen, dass Sie die Linie, die die beiden Punkte auf der Karte verbindet, geändert haben, um die tatsächliche Route vom Zugangspunkt zur Anlage genau darzustellen.",
};
