//TODO

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Breadcrumbs, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import translations from "translations";

export const BreadcrumbContainerSC = styled("div")(
  () => `
    display: flex;
    margin-bottom: -10%;
`
);

export const breadcrumbItemStyle = {
  alignItems: "center",
  boxSizing: "border-box",
  display: "inline-flex",
  height: 24,
  width: "fit-content",
  textDecoration: "none",
  textTransform: "uppercase",
};

const BreadcrumbLink = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.primary.main,
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "16px",
}));

export type Crumbs = { label: string; pathname: string };

interface BreadcrumbProps {
  crumbs?: Crumbs[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ crumbs }) => {
  return (
    <BreadcrumbContainerSC>
      <Breadcrumbs aria-label="breadcrumb">
        {crumbs?.map((crumb, index) => (
          <BreadcrumbLink to={crumb.pathname} key={index}>
            {crumb.pathname === "/" ? (
              ""
            ) : (
              <>
                <BreadcrumbLink to="/">{translations.pages.home.label}</BreadcrumbLink>
                <KeyboardArrowRightIcon />
              </>
            )}
            {crumb.pathname === "/" ? translations.pages.home.label : crumb.label}
          </BreadcrumbLink>
        ))}
      </Breadcrumbs>
    </BreadcrumbContainerSC>
  );
};
export default Breadcrumb;
