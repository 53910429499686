import { logout } from "authentication";
import { de } from "date-fns/locale";
export default {
  save: "Speichern",
  saveChanges: "Änderungen speichern",
  undoChanges: "Änderungen rückgängig machen",
  create: "Erstellen",
  add: "Hinzufügen",
  new: "Neu / Neue / Neuen ",
  assign: "Zuordnen",
  change: "Ändern",
  next: "Weiter",
  edit: "Bearbeiten",
  back: "Zurück",
  continue: "Fortsetzen",
  confirm: "Bestätigen",
  models: "Modelle",
  submit: "Absenden",
  send: "Senden",
  yes: "Ja",
  no: "Nein",
  tryAgain: "Erneut versuchen",
  cancel: "Abbrechen",
  OK: "OK",
  done: "Fertig",
  SearchByFilters: "Suche mit Filtern",
  enterCoordinates: "Koordinaten eingeben",
  createNewAttribute: "Neues Attribut anlegen",
  addExistingAttribute: "Vorhandenes Attribut hinzufügen",
  addNewProject: "Neues Projekt hinzufügen",
  addNewBillingAddress: "Neue Rechnungsadresse hinzufügen",
  approve: "Genehmigen",
  deny: "Ablehnen",
  requestDataCorrection: "Datenkorrektur anfordern",
  remove: "Entfernen",
  reserve: "Reservieren",
  settings: "Einstellungen",
  delete: "Löschen",
  run: "Ausführen",
  unselect: "Abwählen",
  attributeSets: "Attributsätze",
  attributes: "Attribute",
  createdBy: "created by",
  sort: "Sortieren",
  replace: "Ersetzen",
  unassign: "Abwählen",
  newLog: "Neuer Log",
  photo: "Fotos",
  or: "oder",
  check: "Prüfen",
  copyAccessRoute: "Zufahrtsroute kopieren",
  downloadMap: "Karte als JPG herunterladen",
  showRelatedAssets: "Zugehörige Anlagen anzeigen",
  hideAll: "Alle ausblenden",
  showAll: "Alle anzeigen",
  hideRelatedAssets: "Zugehörige Anlagen ausblenden",
  showAllAssets: "Alle Anlagen anzeigen",
  resetAllFilters: "alle Filter zurücksetzen",
  hideAllAssets: "Alle Anlagen ausblenden",
  clickToUpload: "Klicken zum Hochladen",
  here: "hier",
  goToMoreDetails: "Weitere Details anzeigen",
  orDragandDrop: "Ziehen und Ablegen",
  uploadPicture: "Bilder hochladen",
  uploadFiles: "Datei hochladen",
  deletePictures: "Bilder löschen",
  deleteFiles: "Dateien löschen",
  stopDeleting: "Löschen abbrechen",
  logout: "Abmelden",
  editName: "Name bearbeiten",
  changePassword: "Passwort ändern",
};
