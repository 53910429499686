export default {
  volume: "Volume",
  axles: "Achsen",
  radiusOfCurvature: "Krümmungsradius",
  maxLoad: "Tragfähigkeit",
  overAllLength: "Gesamtlänge",
  weighingLength: "Wiegen Länge",
  wagonType: "Wagen Typ",
  tareWeight: "Taragewicht",
  yearOfConstruction: "Baujahr",
  technicalSpecification: "Technical Specifications",
  size: "Size",
};
