/* eslint-disable import/no-unresolved */
import { Box, styled } from "@mui/material";
import projectColors from "themes/projectColors";
import theme from "themes/useCustomTheme";

export const mainContainerStyles = {
  height: "calc(100% - 850px)",
};

export const containerStyles = () => {
  return {
    minHeight: "calc(100% - 0px)",
    width: `calc(100% - 68px)`,
    padding: "1%",
    paddingBottom: 0,
    overflowY: "auto",
    marginLeft: "68px",
    flexGrow: 1,
    transition: "margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
    [theme().breakpoints.between(1200, 1439)]: {
      paddingLeft: 0,
      paddingRight: "20px",
    },
    [theme().breakpoints.between(1440, 1639)]: {
      paddingLeft: 0,
      paddingRight: "20px",
    },
    [theme().breakpoints.up(1640)]: {
      paddingLeft: 0,
      paddingRight: "10px",
    },
  };
};

export const RootWrapperSC = styled("div")(
  ({ theme }) => `
    background-color:${theme.palette.background.default};
    height: 100%;
`
);

export const titleStyles = {
  display: "flex",
  flexDirection: "row",
  paddingLeft: "20px",
};

export const BreadcrumbBoxSC = styled("div")(
  () => `
        display: flex;
        align-items: center;
        gap: 2rem;
        color: ´${projectColors.primary.main}´;
        & span {
          font-size: 29px;
          font-style: normal;
          font-weight: 700;
          height: 44px;
          letter-spacing: 0.15px;
          line-height: 150%;
          margin-right: 32px;
          width: 62px;
        }
`
);
export const SimpleSearchSC = styled("div")(
  ({ width }: { width?: string | number }) => `
        display: flex;
        width: ${width ?? "268px"};
        height: 36px;
        & input {
          padding: 5px 0px
        }
`
);

export const ToolboxSC = styled("div")(
  () => `
        display: flex;
        align-items: center;
        width: 150px;
`
);

export const AlertSC = styled("div")(
  () => `
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        margin-right: 10px;
        & svg {
          cursor: pointer;
          align-self: center;
        }
`
);
export const LanguageSelectorSC = styled("div")(
  () => `
        display: flex;
        justify-izems: space-between;
        align-items: center;
        min-width: 40px;
        & svg {
          right: 0px;
          width: 24px
        }
`
);
export const LanguageDisplaySC = styled("span")(
  () => `
          margin-right: 9px;
          min-width: 40px;
          text-align: "end
`
);

export const ToolsAndSearchSC = styled("div")(
  () => `
        display: flex;
        align-items: center;
`
);

export const HeaderSC = styled("div")(
  () => `
        display: flex;
        align-items: center;
        justify-content: space-between;
`
);

export const HeaderBoxSC = styled("div")(
  () => `
        display: flex;
        flex-direction: column;
`
);

export const NavigationContainerSC = styled("div")(
  () => `
        height: calc(100% - 90px);
        position: absolute;
`
);

export const HeaderWrapper = styled(Box)({
  paddingLeft: "36px",
  paddingRight: "22px",
});
