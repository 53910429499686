export default {
  volume: "Volume",
  axles: "Axles",
  radiusOfCurvature: "Radius of curvature",
  maxLoad: "Max Load",
  overAllLength: "Overall length",
  weighingLength: "Weighing lenght",
  wagonType: "Wagon type",
  tareWeight: "Tare Weight",
  yearOfConstruction: "Year of construction",
  size: "Size",
  technicalSpecification: "Technical Specifications",
};
