import alertManagement from "./alertManagement/de";
import assetManagement from "./assetManagement/de";
import authUser from "./authUser/de";
import configurator from "./configurator/de";
import development from "./development/de";
import deviceManagement from "./deviceManagement/de";
import feedback from "./feedback/de";
import fleetInventory from "./fleetInventory/de";
import fleetManagement from "./fleetManagement/de";
import groups from "./groups/de";
import home from "./home/de";
import map from "./map/de";
import noAccessMessage from "./noAccessMessage/de";
import settings from "./settings/de";
import softwareManagement from "./softwareManagement/de";
import userManagement from "./userManagement/de";
import projects from "./projects/de";
import billingAddress from "./billingAddress/de";
import contact from "./contact/de";

export default {
  projects,
  alertManagement,
  assetManagement,
  authUser,
  configurator,
  development,
  deviceManagement,
  feedback,
  fleetInventory,
  fleetManagement,
  groups,
  home,
  map,
  noAccessMessage,
  settings,
  softwareManagement,
  userManagement,
  billingAddress,
  contact,
};
