import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";
import de from "./de";
import en from "./en";

export type TranslationStrings = typeof de;

type IStrings = LocalizedStringsMethods & TranslationStrings;

export const languages = {
  de,
  en,
};

export const translations: IStrings = new LocalizedStrings(languages, {
  customLanguageInterface: () => "de-De",
});

export const getStringsSave = (value: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return translations.getString(value, translations.getLanguage(), true);
};

export default translations;

export const languageNames = {
  en: "English",
  de: "Deutsch",
};
