export default {
  article_details: "Artikeldetails",
  asset_details: "Assetdetails",
  assign_amount: "Betrag zuordnen ",
  detailView: "Detailansicht",
  new_customer_request: "Neue Kundenanfrage",
  request_details: "Anfragedetails",
  generalInfo: "General Information",
  currentMetrics: "Aktuelle Metriken",
  technicalData: "Technische Daten",
  systemInfo: "System Informationen",
  assignedTo: "Zugeordnet zu",
};
