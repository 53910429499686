export default {
  label: "Device Verwaltung",
  tabs: {
    devices: {
      label: "Devices",
      addButton: "Device hinzufügen",
      tags: "Tags",
      configuration: "Configuration",
      update: "Update",
      assignments: "Assignments",
      makeAssignment: "Make Assignment",
      unassign: "unassign",
      logs: "logs",
      device: "Device",
    },
    deviceGroups: {
      label: "Device Gruppen",
      list: "Device List",
      activities: "Activities",
      newDeviceGroup: "Neue Device Gruppe",
    },
    deviceTypes: {
      label: "Device Typen",
      addButton: "Add Model",
      editModel: "Edit",
      deleteModel: "Delete",
      newDeviceType: "Neuer Device Type",
    },
    deviceDetails: {
      label: "Device Details",
    },
  },
};
