export default {
  baseErrorMessges: {
    required: "Dieses Feld ist erforderlich.",
    dataType: "Dieses Feld hat nicht den richtigen Typ.",
    existingAsset: "Ein Asset mit dieser Kennung existiert bereits.",
    serialNoIsReq: "Kennung ist erforderlich.",
    length: "Der Wert dieses Feldes muss sein",
    min: "min",
    max: "max",
  },
};
