export default {
  baseErrorMessges: {
    required: "This field is required.",
    dataType: "This field is not of the correct type.",
    existingAsset: "An asset with this identifier already exists.",
    serialNoIsReq: "Serial number is required.",
    length: "This fields value must be",
    min: "min",
    max: "max",
  },
};
