export default {
  reference_number: "Ref.-Nr",
  article_number: "Art.-Nr.",
  hrs: "hrs",
  mins: "mins",
  hr: "hr",
  min: "min",
  operators: [
    "enthält",
    "endetMit",
    "gleich",
    "nicht enthält",
    "endet nicht mit",
    "nicht gleich",
    "beginnt nicht mit",
    "beginnt mit",
  ],
};
