import * as React from "react";
import { MenuIconProps } from "routes/routes";
import projectColors, { namelessColor } from "themes/projectColors";

const SettingsIcon = (props: MenuIconProps & JSX.IntrinsicAttributes) => {
  if (props.isActive)
    return (
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
          x="0.5"
          y="0.5"
          width="37"
          height="37"
          rx="7.5"
          fill={projectColors.functional.navBarMenuIconsBackground}
          stroke={projectColors.primary.main}
        />
        <path
          d="M26.43 19.98C26.47 19.66 26.5 19.34 26.5 19C26.5 18.66 26.47 18.34 26.43 18.02L28.54 16.37C28.73 16.22 28.78 15.95 28.66 15.73L26.66 12.27C26.57 12.11 26.4 12.02 26.22 12.02C26.16 12.02 26.1 12.03 26.05 12.05L23.56 13.05C23.04 12.65 22.48 12.32 21.87 12.07L21.49 9.42C21.46 9.18 21.25 9 21 9H17C16.75 9 16.54 9.18 16.51 9.42L16.13 12.07C15.52 12.32 14.96 12.66 14.44 13.05L11.95 12.05C11.89 12.03 11.83 12.02 11.77 12.02C11.6 12.02 11.43 12.11 11.34 12.27L9.33996 15.73C9.20996 15.95 9.26996 16.22 9.45996 16.37L11.57 18.02C11.53 18.34 11.5 18.67 11.5 19C11.5 19.33 11.53 19.66 11.57 19.98L9.45996 21.63C9.26996 21.78 9.21996 22.05 9.33996 22.27L11.34 25.73C11.43 25.89 11.6 25.98 11.78 25.98C11.84 25.98 11.9 25.97 11.95 25.95L14.44 24.95C14.96 25.35 15.52 25.68 16.13 25.93L16.51 28.58C16.54 28.82 16.75 29 17 29H21C21.25 29 21.46 28.82 21.49 28.58L21.87 25.93C22.48 25.68 23.04 25.34 23.56 24.95L26.05 25.95C26.11 25.97 26.17 25.98 26.23 25.98C26.4 25.98 26.57 25.89 26.66 25.73L28.66 22.27C28.78 22.05 28.73 21.78 28.54 21.63L26.43 19.98ZM24.45 18.27C24.49 18.58 24.5 18.79 24.5 19C24.5 19.21 24.48 19.43 24.45 19.73L24.31 20.86L25.2 21.56L26.28 22.4L25.58 23.61L24.31 23.1L23.27 22.68L22.37 23.36C21.94 23.68 21.53 23.92 21.12 24.09L20.06 24.52L19.9 25.65L19.7 27H18.3L17.95 24.52L16.89 24.09C16.46 23.91 16.06 23.68 15.66 23.38L14.75 22.68L13.69 23.11L12.42 23.62L11.72 22.41L12.8 21.57L13.69 20.87L13.55 19.74C13.52 19.43 13.5 19.2 13.5 19C13.5 18.8 13.52 18.57 13.55 18.27L13.69 17.14L12.8 16.44L11.72 15.6L12.42 14.39L13.69 14.9L14.73 15.32L15.63 14.64C16.06 14.32 16.47 14.08 16.88 13.91L17.94 13.48L18.1 12.35L18.3 11H19.69L20.04 13.48L21.1 13.91C21.53 14.09 21.93 14.32 22.33 14.62L23.24 15.32L24.3 14.89L25.57 14.38L26.27 15.59L25.2 16.44L24.31 17.14L24.45 18.27ZM19 15C16.79 15 15 16.79 15 19C15 21.21 16.79 23 19 23C21.21 23 23 21.21 23 19C23 16.79 21.21 15 19 15ZM19 21C17.9 21 17 20.1 17 19C17 17.9 17.9 17 19 17C20.1 17 21 17.9 21 19C21 20.1 20.1 21 19 21Z"
          fill={projectColors.primary.main}
        />
      </svg>
    );
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="38" height="38" rx="8" fill={projectColors.functional.white} />
      <path
        d="M26.43 19.98C26.47 19.66 26.5 19.34 26.5 19C26.5 18.66 26.47 18.34 26.43 18.02L28.54 16.37C28.73 16.22 28.78 15.95 28.66 15.73L26.66 12.27C26.57 12.11 26.4 12.02 26.22 12.02C26.16 12.02 26.1 12.03 26.05 12.05L23.56 13.05C23.04 12.65 22.48 12.32 21.87 12.07L21.49 9.42C21.46 9.18 21.25 9 21 9H17C16.75 9 16.54 9.18 16.51 9.42L16.13 12.07C15.52 12.32 14.96 12.66 14.44 13.05L11.95 12.05C11.89 12.03 11.83 12.02 11.77 12.02C11.6 12.02 11.43 12.11 11.34 12.27L9.33996 15.73C9.20996 15.95 9.26996 16.22 9.45996 16.37L11.57 18.02C11.53 18.34 11.5 18.67 11.5 19C11.5 19.33 11.53 19.66 11.57 19.98L9.45996 21.63C9.26996 21.78 9.21996 22.05 9.33996 22.27L11.34 25.73C11.43 25.89 11.6 25.98 11.78 25.98C11.84 25.98 11.9 25.97 11.95 25.95L14.44 24.95C14.96 25.35 15.52 25.68 16.13 25.93L16.51 28.58C16.54 28.82 16.75 29 17 29H21C21.25 29 21.46 28.82 21.49 28.58L21.87 25.93C22.48 25.68 23.04 25.34 23.56 24.95L26.05 25.95C26.11 25.97 26.17 25.98 26.23 25.98C26.4 25.98 26.57 25.89 26.66 25.73L28.66 22.27C28.78 22.05 28.73 21.78 28.54 21.63L26.43 19.98ZM24.45 18.27C24.49 18.58 24.5 18.79 24.5 19C24.5 19.21 24.48 19.43 24.45 19.73L24.31 20.86L25.2 21.56L26.28 22.4L25.58 23.61L24.31 23.1L23.27 22.68L22.37 23.36C21.94 23.68 21.53 23.92 21.12 24.09L20.06 24.52L19.9 25.65L19.7 27H18.3L17.95 24.52L16.89 24.09C16.46 23.91 16.06 23.68 15.66 23.38L14.75 22.68L13.69 23.11L12.42 23.62L11.72 22.41L12.8 21.57L13.69 20.87L13.55 19.74C13.52 19.43 13.5 19.2 13.5 19C13.5 18.8 13.52 18.57 13.55 18.27L13.69 17.14L12.8 16.44L11.72 15.6L12.42 14.39L13.69 14.9L14.73 15.32L15.63 14.64C16.06 14.32 16.47 14.08 16.88 13.91L17.94 13.48L18.1 12.35L18.3 11H19.69L20.04 13.48L21.1 13.91C21.53 14.09 21.93 14.32 22.33 14.62L23.24 15.32L24.3 14.89L25.57 14.38L26.27 15.59L25.2 16.44L24.31 17.14L24.45 18.27ZM19 15C16.79 15 15 16.79 15 19C15 21.21 16.79 23 19 23C21.21 23 23 21.21 23 19C23 16.79 21.21 15 19 15ZM19 21C17.9 21 17 20.1 17 19C17 17.9 17.9 17 19 17C20.1 17 21 17.9 21 19C21 20.1 20.1 21 19 21Z"
        fill={namelessColor[4]}
        fill-opacity="0.54"
      />
    </svg>
  );
};
export default SettingsIcon;
