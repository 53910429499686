import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { logout } from "./";
import translations from "translations";
import { atom, useRecoilState } from "recoil";

export const logoutOpenModelAtom = atom<boolean>({
  key: "logoutOpenModel",
  default: false,
});

export default function LogoutButton() {
  const [open, setOpen] = useRecoilState(logoutOpenModelAtom);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} aria-label="delete">
        <LogoutIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translations.globals.popupMessages.logoutTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translations.globals.popupMessages.confirmLogout}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {translations.globals.button.cancel}
          </Button>
          <Button variant="contained" onClick={logout} autoFocus>
            {translations.globals.button.logout}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
