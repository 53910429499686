export default {
  creationTime: "Erstellzeit",
  creationDate: "Erstelldatum",
  measurementDate: "Messzeitpunkt",
  last24Hours: "Letzte 24 Stunden",
  last3Days: "Letzte 3 Tage",
  lastWeek: "Letzte Woche",
  last2Weeks: "Letzte 2 Woche",
  lastMonth: "Letzter Monat",
  lastYear: "Letztes Jahr",
  date: "Datum",
  hours: "Stunden",
  minutes: "Minuten",
  hour: "Stunde",
  minute: "Minute",
  morning: "Morgen",
  afternoon: "Nachmittag",
  evening: "Abend",
  timeBucket: {
    tenMin: "10 min",
    oneHour: "1 Stunde",
    sixHours: "6 Stunden",
    twelveHours: "12 Stunden",
    twentyfourHours: "24 Stunden",
  },
};
