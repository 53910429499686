import Breadcrumb, { Crumbs } from "components/molecules/Breadcrumb";
import React from "react";
import { useMatches } from "react-router-dom";
import { BreadcrumbBoxSC, HeaderSC, ToolsAndSearchSC } from "../styles";
import Toolbox from "./Toolbox";

interface HeaderProps {
  brand?: React.ReactNode;
  tools?: React.FC[];
}

const Header: React.FC<HeaderProps> = ({ brand, tools = [] }) => {
  const matches = useMatches();
  const crumbs = matches.reduce<Crumbs[]>((crumbs, match) => {
    const currMatch = match as { handle?: { label?: string }; pathname: string };
    if (currMatch.handle?.label) return crumbs.concat({ label: currMatch.handle.label, pathname: currMatch.pathname });
    return crumbs;
  }, []);

  return (
    <HeaderSC>
      <BreadcrumbBoxSC>
        {brand}
        <Breadcrumb crumbs={crumbs} />
      </BreadcrumbBoxSC>
      <ToolsAndSearchSC>
        <Toolbox tools={tools} />
      </ToolsAndSearchSC>
    </HeaderSC>
  );
};

export default Header;
