export default {
  January: "Jan.",
  February: "Feb.",
  March: "Mrz.",
  April: "Apr.",
  May: "Mai.",
  June: "Jun.",
  July: "Jul.",
  August: "Aug.",
  September: "Sep.",
  October: "Okt.",
  November: "Nov.",
  December: "Dez.",
};
