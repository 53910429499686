import { List } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import projectColors from "themes/projectColors";

export const drawerWidth = "68px";

export const DrawerStyle = {
  list: { mb: "401px" },
  listItem: { justifyContent: "center", paddingBottom: "7px" },
  listIcon: { justifyContent: "center", width: "24px" },
  link: {
    textDecoration: "none",
    display: "flex",
  },
  commonPadding: {
    paddingTop: "70px",
    paddingBottom: "60px",
  },
  drawerFooter: { marginTop: "50px", display: "flex", justifyContent: "center" },
};

export const CustomList = styled(List)({
  flexGrow: 1,
  overflow: "auto",
  minheight: "2em",
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexShrink: 0,
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const DrawerLogoSC = styled("div")({
  padding: "25px 5px 6px 5px",
});

export const Notification = styled("div")(({ theme }) => ({
  background: theme.palette.error.main,
  borderRadius: "64px",
  paddingLeft: "10.5px",
  paddingRight: "10.5px",
  fontSize: "12px",
  paddingTop: "3px",
  color: projectColors.functional.white,
  paddingBottom: "3px",
  marginRight: "20px",
}));

export const OverFlowDrawer = styled(MuiDrawer)(({}) => ({
  ".MuiDrawer-paper": {
    overflow: "visible",
    display: "flex",
    background: projectColors.functional.background,
    borderRight: `1px solid ${projectColors.functional.divider}`,
    height: "100vh",
    width: "68px",
  },
}));
