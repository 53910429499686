export default {
  titleOfCriteria: "Kriterien für die Auswahl von Anlangen",
  performance: "Leistung",
  commissioningDate: "Inbetriebnahmedatum",
  enterdate: "Bitte geben Sie ein Datum ein",
  selectedAssetsPreview: "Ausgewählte Anlagen",
  selectAsset: "Anlage auswählen",
  descriptionOfSelectedAssets: "Definieren Sie zuerst die Kriterien für die Anlagenauswahl, um eine Vorschau zu sehen",
  descriptionOfCriteria:
    "Definieren Sie die Kriterien zur Identifizierung von für diese Sicherheitsbenachrichtigung relevanten Anlagen. Es können mehrere Anlagenmodelle enthalten sein. Sie können Instanzen der ausgewählten Modelle auf der rechten Seite dieser Seite anzeigen.",
  selectValidForm: "Bitte wählen Sie ein Datum",
  selectTitle: "Bitte geben Sie einen Titel ein",
  invalidURL: "Ungültige URL",
  startDate: "Startdatum",
  endDate: "Enddatum",
  until: "bis",
  validFrom: "Gültig seit",
  portalLink: "Portal Link",
  relatedModels: "Zugehörige Modelle",
  file: "Datei",
};
