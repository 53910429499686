import { Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import getTestIDs from "lib/utils/getTestIDs";
import { translations } from "translations";
export const testIDs = getTestIDs();

export default function ErrorPage() {
  const handleClose = () => {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  return (
    <div>
      <Modal
        onClose={handleClose}
        // onConfirm={handleClose}
        // confirmLabel={translations.globals.terms.OK}
        open
        title={translations.globals.helpText.globalErrorMessageHeader}
        // testId={testIDs.erroModal}
      >
        <Typography variant="body1">{translations.globals.helpText.globalErrorMessage}</Typography>
      </Modal>
    </div>
  );
}
