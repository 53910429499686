export default {
  label: "Kontakt",
  phoneNumber: "Telefonnummer",
  note: "Anmerkung",
  addContact: "Kontakt hinzufügen",
  contacts: "Kontakte",
  deleteContactsNotPossible:
    "Es ist nicht möglich, den Kontakt zu löschen, da einer der Kontakte mit mindestens einer Anlage verknüpft ist.",
  deleteContactErrorMessage:
    "Es ist nicht möglich, den Kontakt zu löschen, da der Kontakt mit mindestens einer Anlage verknüpft ist.",
  deleteContactMessage: "Möchten Sie den Kontakt wirklich löschen?",
  deleteContact: "Kontakt löschen",
  addNewContact: "Neuen Kontakt hinzufügen",
  addAnotherContact: "Weiteren Kontakt hinzufügen",
  contactMessage: "Wählen oder erstellen Sie mindestens einen Kontakt und maximal 3 Kontakte.",
};
