export default {
  label: "User Management",
  userAuthorization: "User Authorization",
  tabs: {
    pendingUserRequests: "Pending User Requests",
    userAndRolesAssignment: "User and Roles Assignment",
  },
  assignRolesToUser: {
    label: "Roles To User Assignment",
  },
  inviteUsers: {
    label: "Invite Users",
    formFields: {
      firstName: "First Name",
      lastName: "Last Name",
      emailAdress: "Email Address",
    },
  },
  userRoleManager: {
    inviteUser: "Invite User",
    label: "User Role Manager",
    roleName: "Role Name",
    selectRole: "Select Role",
    assignedTo: "Assigned to",
    noUsersAssignedMessage: "There are no users assigned to this role yet.",
    permissions: "Permissions",
    createdUserRole: "Your user role has been succesfully created.",
    useRoleCreate: "User role created",
    discardChanges: "Discard changes",
    discardMessage:
      "You've commited changes that are not saved. Are you sure you want to quit this page and leave those changes unsaved? ",
  },
  button: {
    create: {
      role: "New role",
    },
  },
  form: {
    label: {
      role: "Edit Role",
      allUsers: "All Users",
      user: "Edit User",
      deleteUserRole: "Delete user role",
      benutzerApprove: "Approve user",
      benutzerDeny: "Deny user",
      editRole: "Edit role",
    },
    message: {
      continueDeleteMessage: "Are you sure you want to continue?",
      approveMessage: "Are you sure you want to approve the user?",
      denyMessage: "Are you sure you want to deny the user?",
      userRoleDeleteMessagePt1: "This user role is currently assignet to",
      userRoleDeleteMessagePt2: "They will no longer have access to certain app areas.",
      userErrorMessage: "the display name should not be empty.",
      userNameErrorMessage: "The user name should not be empty.",
      userLastNameErrorMessage: "The user last name should not be empty.",
      phoneNumerErrorMessage: "The entered value must be a phone number.",
      streetAndNumberErrorMessage:
        "Please enter the street name followed by a comma and the house number (e.g. Hauptstraße, 123).",
      postalCodeErrorMessage: "Please enter a valid postal code. In Germany, the postal code consists of five digits.",
      requiredInformation: "This information is required.",
      tenantNameExists: "The name of the Tenant already exists, please enter another name.",
    },
    edit: {
      tabLabel: {
        editRole: "Change Role",
        assignScope: "Assign Permissions",
        assignUser: "Assign Users",
      },
      listLabel: {
        assigned: "Assigned",
        unassigned: "Unassigned",
      },
    },
  },
  table: {
    column: {
      description: "Description",
      email: "Email",
      firstName: "First Name",
      lastName: "Last Name",
      name: "Name",
      role: "Roles of the User",
      tenant: "Tenant / Company",
      value: "Value",
      userRole: "User role name",
      roleDescription: "Role description",
      pendingRequestsAction: "Action",
    },
  },
};
