export default {
  saved: "Daten wurden erfolgreich gespeichert",
  updated: "Daten wurden erfolgreich aktualisiert",
  deleted: "Daten wurden erfolgreich gelöscht",
  sent: "Anfrage wurde erfolgreich gesendet",
  error: "Systemfehler - Bitte wenden Sie sich an den Administrator",
  assetReviewed: "Anlage wurde erfolgreich geprüft",
  assetCreated: "Die Anlage wurde erfolgreich erstellt und wird durch den Platformbetreiber geprüft.",
  assetTypeCreated: "Der Anlagentyp wurde erfolgreich erstellt.",
  assetModelCreated: "Das Anlagenmodell wurde erfolgreich erstellt.",
  assetTypeEdited: "Der Anlagentyp wurde erfolgreich bearbeitet.",
  assetModelEdited: "Das Anlagenmodell wurde erfolgreich bearbeitet.",
  safetyNoteCreated: "Die Produktsicherheitsmitteilung wurde erfolgreich erstellt.",
  safetyNoteEdited: "Die Produktsicherheitsmitteilung wurde erfolgreich bearbeitet.",
  assetEdited: "Die Anlage wurde erfolgreich bearbeitet.",
  errorCodeLetters: "Die Kennung muss das Format XX-XXXXXXXXXXXX (Buchstabe(n)-Nummer) haben.",
  selectTenant: "Bitte wählen Sie eine Organisation aus",
  selectAssetType: "Bitte wählen Sie eine Anlagentyp aus",
  selectAssetModel: "Bitte wählen Sie eine Anlagenmodell aus",
  selectUnit: "Bitte wählen Sie eine Einheit aus",
  enterName: "Bitte geben Sie einen Namen ein",
  selectType: "Bitte wählen Sie eine Eingabedaten aus",
  selectProject: "Bitte wählen Sie ein Projekt aus",
  selectBillingAddress: "Bitte wählen Sie eine Rechnungsadresse aus",
  selectContact: "Bitte wählen Sie mindestens einen Kontakt aus",
  exportSuccessful: "Der Export war erfolgreich.",
  exportNotSuccessful: "Der Export war leider nicht erfolgreich. Bitte wenden Sie sich an Ihren Administrator.",
};
