// eslint-disable-next-line import/no-extraneous-dependencies
import { merge } from "ts-deepmerge";
import projectColors from "./projectColors";
import { PaletteMode, PaletteOptions } from "@mui/material";
import { Functional, Primary, ProjectColors, Spare, StatusAndTag } from "./types";

type CustomTheme = Partial<ProjectColors> & PaletteOptions;

const PaletteDark = merge(projectColors, {
  mode: "dark" as PaletteMode,
  functional: {
    black: "none",
  } as Functional,
  primary: {} as PaletteOptions["primary"] & Primary,
  spare: {} as Spare,
  statusAndTag: {} as StatusAndTag,
  projectColors,
} as CustomTheme) as PaletteOptions;

export default PaletteDark;
