export default {
  Helptext1: "Various search terms can be separated by space (The search result will contain the string).",
  Helptext2: "Double quote '...' will search for an exact string (in some cases this could speed up the search).",
  Helptext3: "It is also possible to search for multiple exact strings '...' '...' '...'.",
  globalErrorMessageHeader: "Error",
  globalErrorMessage:
    "An error has been occurred during your request and the IT support has been informed. We will resolve this issue as soon as possible and are sorry for the inconvenience.",
  SelectOrCreateAttribute1: "Select or",
  SelectOrCreateAttribute2: "an attribute to define its parameters",
  createNewAttributeSets1: "No attribute sets have been created yet. Click ",
  createNewAttributeSets2: " ,to create them.",
  click: "click",
  toCreate: ", to create it!",
  selectAttributeToChangeParameters: "Select an attribute to change its parameters",
  resetPassword: "You will be redirected to an external website to reset your password. Do you want to proceed?",
};
