import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import React from "react";
import useIsAuthenticated from "useIsAuthenticated";
import { msalInstance } from "./";
const authDisabled = process.env.REACT_APP_DISABLE_AUTH === "true";
interface AuthenticationCompontentProps {
  children: React.ReactNode;
}

export const AuthenticationCompontent: React.FC<AuthenticationCompontentProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  if (authDisabled || !isAuthenticated) return <>{children}</>;
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>{children}</MsalAuthenticationTemplate>
      <UnauthenticatedTemplate />
    </MsalProvider>
  );
};

export default AuthenticationCompontent;
