import { DrawerProps } from "@mui/material/Drawer";
import MiniDrawer from "./Drawer";

export interface NavigatorProps extends DrawerProps {
  setTitle: (props: string) => void;
  open: boolean;
  handleDrawer: (value: boolean) => void;
}

const Navigator: React.FC<NavigatorProps> = ({ setTitle, open, handleDrawer }) => {
  return <MiniDrawer setTitle={setTitle} open={open} handleDrawer={handleDrawer} />;
};
export default Navigator;
