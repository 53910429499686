export default {
  label: "Map",
  route: "Route",
  AssetPositioning: "Asset Positioning",
  coordinateN: "coordinate N",
  coordinateE: "coordinate E",
  lat: "Lat. ° (N)",
  lon: "Lon. ° (E)",
  searchRadius: "Search Radius (m)",
  WGS: "Decimal Degree (WGS84)",
};
