import { ApolloClient, from, HttpLink } from "@apollo/client";
import cache from "./cache";
import { setContext } from "@apollo/client/link/context";
import { getTokenAsync, logout } from "authentication";
import errorLink from "./errorLink";
import resolvers from "./resolvers";
// import typeDefs from "./typeDefs";
const authLink = setContext(
  (_, { headers }) =>
    new Promise((resolve, reject) => {
      getTokenAsync()
        .then((token) => {
          resolve({
            headers: {
              ...headers,
              authorization: token ? `Bearer ${token}` : "",
            },
          });
        })
        .catch((e) => {
          logout().finally(() => reject(e));
        });
    })
);

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI ?? "",
});

const link = process.env.REACT_APP_DISABLE_AUTH === "true" ? httpLink : from([authLink, httpLink]);

const client = new ApolloClient({
  cache: cache,

  link: from([errorLink, link]),
  resolvers,
  connectToDevTools: true,
});

export default client;
