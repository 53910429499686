export default {
  changeasset:
    "The asset was updated by another user in the meanwhile and your changes cannot be stored. Please try again.",
  general: "General",
  header: "Attention",
  noDataAvailable: "No data available...",
  noSavetyNotes: "No safety notes have been created yet",
  noAssets: "there are no more new Assets for this tenant",
  errorPage: "Oops, an error occurred!",
};
