import { no } from "change-case";
import { te } from "date-fns/locale";

export default {
  assets: {
    asset: "Asset",
    assets: "Assets",
    assetLevel: "Asset Level",
    assetModel: "Model",
    models: "Models",
    model: "Model",
    assetType: "Type",
    assetTypes: "Types",
    provider: "Provider",
    serialNo: "Serial No.",
    tags: "Tags",
    user: "user",
    itemManufacturer: "Manufacturer",
    assetId: "Asset Id",
    AssetLocalization: "Asset localization ",
    identifier: "Identifier",
    createOrSelectTags: "select tags",
    createNewAssetTag: "Create new asset tag",
    errorAssetTag: "Tag already exists",
    noAssets: "No assets",
  },
  user: {
    name: "User",
    plural: "Users",
    columns: {
      id: "ID",
      userName: "User",
      contact: "Contact",
      myProfile: "My Profile",
      contactList: "Contact List",
      tenant: "Tenant",
      roles: "Roles",
      registrationDate: "Registration Date",
      firstName: "First Name",
      lastName: "Last Name",
      status: "status",
      approved: "Approved",
      pending: "Pending",
      authenticationProvider: "Authentication Provider",
      email: "Email",
      language: "Language",
      userDescription: "User Description",
      lastLogin: "Last Login",
      createdAt: "Created on",
      modifiedAt: "Modified on",
      isDeleted: "is deleted",
      displayName: "Display Name",
      declined: "Declined",
    },
  },
  tenant: {
    name: "Tenant",
    plural: "Tenants",
    phone: "Phone number",
    zipCode: "Postal Code",
    country: "Country",
    city: "City",
    streetAndNumber: "Street and number",
    columns: {
      id: "ID",
      tenantIdentifier: "Tenant ID",
      tenantName: "Tenant Name",
      isDeleted: "is deleted",
      createdAt: "Created on",
      modifiedAt: "Modified on",
      tenantRole: "Tenant Role",
      tenantType: "Tenant Type",
    },
  },
  role: {
    name: "Role",
    plural: "Roles",
    tenantRole: {
      "2ab640dd-39c0-4dc5-9a3f-1919f8efd4d0": "Platform Operator",
      "6413f36a-0dd6-42a5-a3ac-a99f4539f48e": "Rescue Service",
      "f8aa95db-158e-4a9e-8a0f-b5dcbcc5b1b7": "Plant Operator",
    },
    columns: {
      id: "ID",
      name: "Name",
      description: "Description",
      isDefaultRole: "is default role",
      createdAt: "Created on",
      modifiedAt: "Modified on",
      isDeleted: "is deleted",
    },
  },
};
