export default {
  Helptext1: "Mehrere Suchbegriffe können durch Leerzeichen getrennt werden.",
  Helptext2: "Mit Anführungszeichen '...' kann nach einer exakten Zeichenfolge gesucht werden.",
  Helptext3: "Es ist auch möglich nach mehreren exakten Zeichenfolgen '...' '...' '...' zu suchen.",
  globalErrorMessageHeader: "Fehler",
  globalErrorMessage:
    "Während Ihrer Anfrage ist ein Fehler aufgetreten und der IT-Support wurde informiert. Wir werden dieses Problem so schnell wie möglich beheben und entschuldigen uns für die Unannehmlichkeiten.",
  SelectOrCreateAttribute1: "Wählen oder ",
  selectAttributeToChangeParameters: "Wählen Sie ein Attribut, um seine Parameter zu ändern",
  click: "klicken Sie",
  toCreate: ", um sie zu erstellen!",
  SelectOrCreateAttribute2: "Sie ein Attribut, um seine Parameter zu definieren",
  createNewAttributeSets1: "Es sind noch keine Attributsätze erstellt worden. Klicken Sie ",
  createNewAttributeSets2: " , um sie zu erstellen.",
  resetPassword:
    "Sie werden auf eine externe Website weitergeleitet, um Ihr Passwort zurückzusetzen. Möchten Sie fortfahren?",
};
