export default {
  article_details: "Article Details",
  asset_details: "Asset Details",
  assign_amount: "Assign Amount",
  detailView: "Detail View",
  new_customer_request: "New Custom request",
  request_details: "Request Details",
  generalInfo: "General Information",
  currentMetrics: "Current metrics",
  technicalData: "Technical Data",
  systemInfo: "System Informationen",
  assignedTo: "Assigned to",
};
