// colors name taken from here
//https://chir.ag/projects/name-that-color/

import { ProjectColors } from "./types";

export const namelessColor = {
  1: "#96B4C3",
  2: "#568498",
  3: "#787673",
  4: "#000000",
  5: "#65FFDA",
};

const projectColors: ProjectColors = {
  functional: {
    background: "#F1F6F8",
    black: "#212121",
    blackFocussed: "#646464",
    blackHover: "#171717",
    darkGray: "#717171",
    divider: "#E0E0E0",
    grayBackground: "#FAFAFA",
    lightGray: "#9E9E9E",
    lightTextPrimary: "rgba(0, 0, 0, 0.87)",
    lightTextSecondary: "rgba(0, 0, 0, 0.60)",
    navBarMenuIconsBackground: "#E0E9F0",
    white: "#FFFFFF",
    info100: "#EEF8FE",
    border: "#9E9E9E66",
  },
  primary: {
    main: "#1A6B8B",
    focus: "#5F97AE",
    hover: "#124B61",
  },
  spare: {
    spare1: "#F6D0D9",
    spare2: "#C7E9FB",
    spare3: "#E0E0FF",
    spare4: "#CDF7F6",
  },
  statusAndTag: {
    asset: "#E6D2EA",
    device: "#FFFFFF",
    success: "#329F5B",
    warning: "#F3A712",
    error: "#D62839",
  },
  namelessColor,
};

export default projectColors;
export const themeProjectColors = projectColors;
export type ThemeProjectColors = typeof projectColors;
