import type * as Types from '../../../types';

import client from "apollo/client";
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserInfoQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type GetUserInfoQuery = { __typename?: 'Query', users?: { __typename?: 'UserCollectionSegment', items?: Array<{ __typename?: 'User', approvalStatus: Types.ApprovalStatus, tenantId: string, displayName: string, id: string, userBridgeRoles: Array<{ __typename?: 'UserBridgeRole', id: string, role: { __typename?: 'Role', roleBridgeScopes: Array<{ __typename?: 'RoleBridgeScope', id: string, scope: { __typename?: 'Scope', description?: string | null, id: string, value: string, name: string } }> } }>, tenant: { __typename?: 'Tenant', tenantRole: Types.TenantRole } }> | null } | null };


        export type ExecGetUserInfoOptions = Omit<
        Apollo.QueryOptions<
        GetUserInfoQueryVariables, GetUserInfoQuery
        >,
        "query"
      >;
    export const execGetUserInfoQuery = (options: ExecGetUserInfoOptions) =>
    client.query< GetUserInfoQuery, GetUserInfoQueryVariables>({
      ...options,
      query: GetUserInfoDocument,
    });
    

export const GetUserInfoDocument = gql`
    query getUserInfo($email: String!) {
  users(where: {email: {eq: $email}}) {
    items {
      approvalStatus
      tenantId
      userBridgeRoles {
        id
        role {
          roleBridgeScopes {
            id
            scope {
              description
              id
              value
              name
            }
          }
        }
      }
      displayName
      id
      tenant {
        tenantRole
      }
    }
  }
}
    `;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
          export type ResultType<T> = Types.ResultType<T>;
          export type PaginatedResultType<T> = Types.PaginatedResultType<T>;
          export type NonMaybe<T> = Types.NonMaybe<T>;
          export type Maybe<T> = Types.Maybe<T>;