export default {
  label: "Alarmverwaltung",
  alerts: "Alarme",
  alertDefinitions: "Alarmverwaltung",
  scopeEdit: {
    operator: "Operator",
    value: "Wert",
    WarningScope: "Warnungs Bereich",
  },
  NotificationResubmissionInterval: {
    h1: "1 h",
    h6: "6 h",
    h12: "12 h",
    h24: "24 h",
    never: "Never",
  },
  createAlert: "Alarm erstellen",
};
