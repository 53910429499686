export default {
  label: "Benutzerverwaltung",
  userAuthorization: "User Authorization",
  tabs: {
    pendingUserRequests: "Ausstehende Benutzeranfragen",
    userAndRolesAssignment: "Benutzer- und Rollenzuweisung",
  },
  assignRolesToUser: {
    label: "Rollen zu Benutzer Zuweisung",
  },
  inviteUsers: {
    label: "Benutzer einladen",
    formFields: {
      firstName: "Vorname",
      lastName: "Nachname",
      emailAdress: "Email-Adresse",
    },
  },
  userRoleManager: {
    inviteUser: "Benutzer einladen",
    label: "Benutzer-Rollenmanager",
    roleName: "Rollenname",
    selectRole: "Select Role",
    assignedTo: "Zugewiesen an",
    noUsersAssignedMessage: "Es gibt noch keine Benutzer, die dieser Rolle zugeordnet sind.",
    permissions: "Berechtigungen",
    createdUserRole: "Ihre Benutzerrolle wurde erfolgreich erstellt.",
    useRoleCreate: "Benutzerrolle erstellt",
    discardChanges: "Änderungen verwerfen",
    discardMessage:
      "Sie haben Änderungen vorgenommen, die nicht gespeichert sind. Sind Sie sicher, dass Sie diese Seite verlassen und die Änderungen ungespeichert lassen wollen?",
  },
  button: {
    create: {
      role: "Neue Rolle",
    },
  },
  form: {
    label: {
      role: "Rolle bearbeiten",
      allUsers: "Alle Benutzer",
      user: "Benutzer bearbeiten",
      deleteUserRole: "Benutzerrolle löschen",
      benutzerApprove: "Benutzer genehmigen",
      benutzerDeny: "Benutzer ablehnen",
      editRole: "Rolle bearbeiten",
    },
    message: {
      continueDeleteMessage: "Sind Sie sicher, dass Sie weitermachen wollen?",
      approveMessage: "Sind Sie sicher, dass Sie den Benutzer genehmigen wollen?",
      denyMessage: "Sind Sie sicher, dass Sie den Benutzer ablehnen wollen?",
      userRoleDeleteMessagePt1: "Diese Benutzerrolle ist derzeit zugewiesen an",
      userRoleDeleteMessagePt2: "Sie werden keinen Zugang mehr zu bestimmten App-Bereichen haben.",
      userErrorMessage: "der Benutzer darf nicht leer sein.",
      userNameErrorMessage: "Der Benutzername darf nicht leer sein.",
      userLastNameErrorMessage: "Der Benutzer Nachname darf nicht leer sein.",
      phoneNumerErrorMessage: "Der eingegebene Wert muss eine Telefonnummer sein.",
      streetAndNumberErrorMessage:
        "Bitte geben Sie den Straßennamen gefolgt von einem Komma und der Hausnummer ein (z.B. Hauptstraße, 123).",
      postalCodeErrorMessage:
        "Bitte geben Sie eine gültige Postleitzahl ein. In Deutschland besteht die Postleitzahl aus fünf Ziffern.",
      requiredInformation: "Diese Information ist erforderlich.",
      tenantNameExists: "Der Name der Organisation existiert bereits, bitte geben Sie einen anderen Namen ein.",
    },
    edit: {
      tabLabel: {
        editRole: "Rolle ändern",
        assignScope: "Berechtigungen zuordnen",
        assignUser: "Benutzer zuordnen",
      },
      listLabel: {
        assigned: "Zugeordnet",
        unassigned: "Verfügbar",
      },
    },
  },
  table: {
    column: {
      description: "Beschreibung",
      email: "Email",
      firstName: "Vorname",
      lastName: "Nachname",
      name: "Name",
      role: "Rollen des Benutzers",
      tenant: "Organisation / Unternehmen",
      value: "Wert",
      userRole: "Name der Benutzerrolle",
      roleDescription: "Beschreibung der Rolle",
      pendingRequestsAction: "Aktionen",
    },
  },
};
