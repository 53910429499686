export default {
  operator: "Organisation",
  project: "Projekt",
  identificationNumber: "Kennnummer",
  selectField: "Feld auswählen",
  selectOperator: "Organisation auswählen",
  rule: "Regel",
  ruleSet: "Regelset",
  not: "Nicht",
  selectValueSource: "Wertquelle wählen",
  errorGroupQuery: "Es konnten keine Ergebnisse zurückgegeben werden, da es ein Problem mit der Regeldefinition gibt.",
  updateQueryMessage: "Bitte aktualisieren Sie die Regeldefinition für Ihre Gruppe um Ergebnisse zu erhalten.",
  emptyGroupNameMessage: "Der Gruppenname darf nicht leer sein.",
};
