export default {
  label: "Contact",
  phoneNumber: "Phone number",
  note: "Note",
  addContact: "Add contact",
  contacts: "Contacts",
  deleteContactsNotPossible:
    "It is not possible to delete the contact because one of the contacts is linked to an asset.",
  deleteContactErrorMessage: "It is not possible to delete the contact because the contact is linked to an asset.",
  deleteContactMessage: "Do you really want to delete the contact?",
  deleteContact: "Delete contact",
  addNewContact: "Add new contact",
  addAnotherContact: "Add another contact",
  contactMessage: "Select or create at least one and at most 3 contacts.",
};
