export default {
  label: "Software Management",
  tabs: {
    myEdgeDevices: {
      label: "Meine Edge Devices",
      lastConnection: "Last Connection",
      upDateStatus: "Update Status",
      sshSession: "SSH Session",
      appSetSubscriptions: "App Set Subscriptions ",
      activities: "Aktivitäten",
    },
    appSets: {
      label: "App Sets",
      appSet: "App Set",
      newAppset: "Neues App Set",
      apps: "Apps",
      app: "App",
      appCategory: "App Category",
      deviceList: "Device Liste",
      activities: "Aktivitäten",
      appCategories: "App Kategorien",
      developer: "Entwickler",
      version: "Version",
      revision: "Revision",
      releaseDate: "Release Date",
      size: "Size[MB]",
    },

    apps: {
      label: "Apps",
      addButton: "App Hinzufügen",
      removeButton: "App Entfernen",
    },
    updatePropositions: {
      label: "Update Propositions",
    },
  },
};
