export default {
  label: "Karte",
  route: "Route",
  AssetPositioning: "Anlagenpositionierung",
  lat: "Lat. ° (N)",
  lon: "Lon. ° (E)",
  coordinateN: "Koordinate N",
  coordinateE: "Koordinate E",
  searchRadius: "Suchradius (m)",
  WGS: "Dezimalgrad (WGS84):",
};
