import * as React from "react";
import { MenuIconProps } from "routes/routes";
import projectColors, { namelessColor } from "themes/projectColors";

const AssetManagementIcon = (props: MenuIconProps & JSX.IntrinsicAttributes) => {
  if (props.isActive)
    return (
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
          x="0.5"
          y="0.5"
          width="37"
          height="37"
          rx="7.5"
          fill={projectColors.functional.navBarMenuIconsBackground}
          stroke={projectColors.primary.main}
        />
        <path
          d="M17 30C17 29.45 17.196 28.979 17.588 28.587C17.9793 28.1957 18.45 28 19 28V22.275C19.15 22.3417 19.3083 22.3917 19.475 22.425C19.6417 22.4583 19.8167 22.475 20 22.475C20.7 22.475 21.2877 22.2373 21.763 21.762C22.2377 21.2873 22.475 20.7 22.475 20V19.775L25.675 20.55C25.9417 20.6167 26.1833 20.7333 26.4 20.9C26.6167 21.0667 26.7917 21.2667 26.925 21.5L29.2 25.6C29.45 26.05 29.5417 26.525 29.475 27.025C29.4083 27.525 29.1917 27.9583 28.825 28.325C28.375 28.775 27.8377 29 27.213 29C26.5877 29 26.05 28.775 25.6 28.325L21 23.725V28C21.55 28 22.021 28.1957 22.413 28.587C22.8043 28.979 23 29.45 23 30H17ZM10 28V26H15V28H10ZM14.15 23.75L9.65 22.475C9.16667 22.3417 8.77067 22.0707 8.462 21.662C8.154 21.254 8 20.7917 8 20.275C8 19.6417 8.221 19.104 8.663 18.662C9.10433 18.2207 9.64167 18 10.275 18H18.525C18.2083 18.2333 17.9627 18.521 17.788 18.863C17.6127 19.2043 17.525 19.5833 17.525 20C17.525 20.3833 17.6 20.7333 17.75 21.05C17.9 21.3667 18.1083 21.6417 18.375 21.875L15.75 23.55C15.5167 23.6833 15.2627 23.7707 14.988 23.812C14.7127 23.854 14.4333 23.8333 14.15 23.75ZM20 21.5C19.5833 21.5 19.2293 21.354 18.938 21.062C18.646 20.7707 18.5 20.4167 18.5 20C18.5 19.5833 18.646 19.2293 18.938 18.938C19.2293 18.646 19.5833 18.5 20 18.5C20.4167 18.5 20.7707 18.646 21.062 18.938C21.354 19.2293 21.5 19.5833 21.5 20C21.5 20.4167 21.354 20.7707 21.062 21.062C20.7707 21.354 20.4167 21.5 20 21.5ZM22.3 19.05C22.1167 18.6 21.821 18.2333 21.413 17.95C21.0043 17.6667 20.5333 17.525 20 17.525C19.8167 17.525 19.6417 17.5417 19.475 17.575C19.3083 17.6083 19.15 17.6583 19 17.725V13.775C19 13.4917 19.0543 13.225 19.163 12.975C19.271 12.725 19.425 12.5083 19.625 12.325L23.05 9.125C23.4333 8.75833 23.8793 8.55 24.388 8.5C24.896 8.45 25.3667 8.55833 25.8 8.825C26.3333 9.15833 26.6793 9.629 26.838 10.237C26.996 10.8457 26.9083 11.4167 26.575 11.95L22.3 19.05ZM8 16V14H13V16H8ZM11 12V10H17V12H11Z"
          fill={projectColors.primary.main}
        />
      </svg>
    );
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="38" height="38" rx="8" fill={projectColors.functional.white} />
      <path
        d="M17 30C17 29.45 17.196 28.979 17.588 28.587C17.9793 28.1957 18.45 28 19 28V22.275C19.15 22.3417 19.3083 22.3917 19.475 22.425C19.6417 22.4583 19.8167 22.475 20 22.475C20.7 22.475 21.2877 22.2373 21.763 21.762C22.2377 21.2873 22.475 20.7 22.475 20V19.775L25.675 20.55C25.9417 20.6167 26.1833 20.7333 26.4 20.9C26.6167 21.0667 26.7917 21.2667 26.925 21.5L29.2 25.6C29.45 26.05 29.5417 26.525 29.475 27.025C29.4083 27.525 29.1917 27.9583 28.825 28.325C28.375 28.775 27.8377 29 27.213 29C26.5877 29 26.05 28.775 25.6 28.325L21 23.725V28C21.55 28 22.021 28.1957 22.413 28.587C22.8043 28.979 23 29.45 23 30H17ZM10 28V26H15V28H10ZM14.15 23.75L9.65 22.475C9.16667 22.3417 8.77067 22.0707 8.462 21.662C8.154 21.254 8 20.7917 8 20.275C8 19.6417 8.221 19.104 8.663 18.662C9.10433 18.2207 9.64167 18 10.275 18H18.525C18.2083 18.2333 17.9627 18.521 17.788 18.863C17.6127 19.2043 17.525 19.5833 17.525 20C17.525 20.3833 17.6 20.7333 17.75 21.05C17.9 21.3667 18.1083 21.6417 18.375 21.875L15.75 23.55C15.5167 23.6833 15.2627 23.7707 14.988 23.812C14.7127 23.854 14.4333 23.8333 14.15 23.75ZM20 21.5C19.5833 21.5 19.2293 21.354 18.938 21.062C18.646 20.7707 18.5 20.4167 18.5 20C18.5 19.5833 18.646 19.2293 18.938 18.938C19.2293 18.646 19.5833 18.5 20 18.5C20.4167 18.5 20.7707 18.646 21.062 18.938C21.354 19.2293 21.5 19.5833 21.5 20C21.5 20.4167 21.354 20.7707 21.062 21.062C20.7707 21.354 20.4167 21.5 20 21.5ZM22.3 19.05C22.1167 18.6 21.821 18.2333 21.413 17.95C21.0043 17.6667 20.5333 17.525 20 17.525C19.8167 17.525 19.6417 17.5417 19.475 17.575C19.3083 17.6083 19.15 17.6583 19 17.725V13.775C19 13.4917 19.0543 13.225 19.163 12.975C19.271 12.725 19.425 12.5083 19.625 12.325L23.05 9.125C23.4333 8.75833 23.8793 8.55 24.388 8.5C24.896 8.45 25.3667 8.55833 25.8 8.825C26.3333 9.15833 26.6793 9.629 26.838 10.237C26.996 10.8457 26.9083 11.4167 26.575 11.95L22.3 19.05ZM8 16V14H13V16H8ZM11 12V10H17V12H11Z"
        fill={namelessColor[4]}
        fill-opacity="0.6"
      />
    </svg>
  );
};
export default AssetManagementIcon;
