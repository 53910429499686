import React from "react";
import { ToolboxSC } from "../styles";

interface ToolboxProps {
  tools: React.FC[];
}

const Toolbox: React.FC<ToolboxProps> = (props) => {
  return <ToolboxSC>{props.tools.map((tool) => tool(props))}</ToolboxSC>;
};

export default Toolbox;
