export default {
  authorization: "authorization",
  cancel: "Cancel",
  delete: "Delete",
  wear: "Wear",
  submit: "Submit",
  create: "Create",
  dashboard: "Dashboard",
  asset: "Asset",
  details: "Details",
  alerts: "Alerts",
  confirmationOfOrder: "Confirmation of Order",
  feature: "Feature",
  equipment: "Equipment",
  select: "Select",
  description: "Description",
  administrator: "Administrator",
  digger: "Digger",
  yearOfConstruction: "Year of Construction",
  yearOfProduction: "Year of Production",
  confirm: "Confirm",
  edit: "Edit",
  event: "Event",
  hotline: "Hotline",
  noHotlineAvailable: "No Hotline Available",
  continue: "Continue",
  machine: "Machine",
  media: "Media",
  deliveryNote: "Delivery Note",
  noImage: "No Image",
  noDescription: "No Description",
  tenant: "Tenant",
  defaultValue: "Default Value",
  scheduler: "Scheduler",
  productArea: "Product Area",
  productDivision: "Product Division",
  productGroup: "Product Group",
  DefaultValue: "Default Value",
  productLine: "Product Line",
  productFamily: "Product Family",
  productModel: "Product Model",
  productCategory: "Product Category",
  wheelLoader: "Wheel Loader",
  invoice: "Invoice",
  crawler: "Crawler",
  quickChanger: "Quick Changer",
  weldingBadge: "Welding Badge",
  save: "Save",
  search: "Search",
  listOfParts: "List of Parts",
  towerPiece: "Tower Piece",
  next: "Next",
  yes: "Yes",
  no: "No",
  back: "Back",
  Reset: "Reset",
  assign: "Assign",
  rentalAgreement: "rental agreement",
  topSlewingCranes: "top slewing cranes",
  OK: "OK",
  field: "Field",
  key: "Key",
  value: "Value",
  all: "All",
  user: "User",
  paired: "Paired",
  notPaired: "Not Paired",
  receiver: "Receiver",
  list: "List",
  rest: "Rest",
  interval: "Interval",
  state: "State",
  coupling: "Coupling",
  never: "never",
  optional: "Optional",
  good: "Good",
  critical: "Critical",
  unknown: "Unknown",
  imageFormat: "SVG, PNG, JPG or GIF (max. 3MB)",
  pdfFormat: "PDF (max. 3MB)",
  template: "Template",
  column: "Column",
  row: "Row",
  resetPassword: "Reset Password",
};
