export default {
  titleOfCriteria: "Criteria for Asset Selection",
  performance: "Performance",
  commissioningDate: "Commissioning Date",
  enterdate: "Please enter a date",
  selectedAssetsPreview: "Selected Assets Preview",
  descriptionOfSelectedAssets: "Define the criteria for asset selection first to see its preview",
  descriptionOfCriteria:
    "Define the criteria for identifying assets relevant to this safety notice. Multiple asset models may be included. You can preview instances of the selected models on the right side of this page.",
  selectValidForm: "Please select a valid form",
  selectTitle: "Please enter a title",
  invalidURL: "Invalid URL",
  selectAsset: "Select Asset",
  startDate: "Start Date",
  endDate: "End Date",
  until: "until",
  validFrom: "Valid from",
  portalLink: "Portal Link",
  relatedModels: "Related Models",
  file: "File",
};
