export const DrawerFooterStyle = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "15px",
  },
  centerInfo: {
    justifyContent: "center",
    flexGrow: 7,
    marginLeft: "20px",
  },
  rightIcon: {
    flexGrow: 1,
  },
  infoTitle: {
    fontSize: "14px",
  },
  infoSubTitle: {
    fontSize: "12px",
  },
};
