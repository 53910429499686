import * as React from "react";
import { MenuIconProps } from "routes/routes";
import projectColors, { namelessColor } from "themes/projectColors";

const UserAuthIcon = (props: MenuIconProps & JSX.IntrinsicAttributes) => {
  if (props.isActive)
    return (
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
          x="0.5"
          y="0.5"
          width="37"
          height="37"
          rx="7.5"
          fill={projectColors.functional.navBarMenuIconsBackground}
          stroke={projectColors.primary.main}
        />
        <path
          d="M19 8L10 12V18C10 23.55 13.84 28.74 19 30C24.16 28.74 28 23.55 28 18V12L19 8ZM26 18C26 22.52 23.02 26.69 19 27.93C14.98 26.69 12 22.52 12 18V13.3L19 10.19L26 13.3V18ZM14.41 18.59L13 20L17 24L25 16L23.59 14.58L17 21.17L14.41 18.59Z"
          fill={projectColors.primary.main}
        />
      </svg>
    );
  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x="0.5"
        y="0.5"
        width="37"
        height="37"
        rx="7.5"
        fill={projectColors.functional.white}
        stroke={projectColors.functional.white}
      />
      <path
        d="M19 8L10 12V18C10 23.55 13.84 28.74 19 30C24.16 28.74 28 23.55 28 18V12L19 8ZM26 18C26 22.52 23.02 26.69 19 27.93C14.98 26.69 12 22.52 12 18V13.3L19 10.19L26 13.3V18ZM14.41 18.59L13 20L17 24L25 16L23.59 14.58L17 21.17L14.41 18.59Z"
        fill={namelessColor[4]}
        fill-opacity="0.6"
      />
    </svg>
  );
};
export default UserAuthIcon;
