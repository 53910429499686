export default {
  addGeoFence: {
    label: "Neuen Standort hinzufügen",
  },
  general: "Allgemein",
  geofencesTable: {
    label: "Übersicht der Standorte",
  },
  label: "Flotteninventar",
  map: "Karte",
  movement: "Bewegung",
  movementHistory: "Bewegungsverlauf",
  multiSelectLabel: "Standort",
  noPolygonWarning: "Standort muss festgesetzt werden",
  technicalDetails: "Technische Details",
};
