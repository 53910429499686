import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import React from "react";
import { reactPlugin } from "./AppInsights";
import ErrorPage from "./ErrorPage";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}
const Wrapper: React.FC<ErrorBoundaryProps> = ({ children }) => <>{children}</>;
const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  return (
    <AppInsightsErrorBoundary onError={ErrorPage} appInsights={reactPlugin}>
      <Wrapper>{children}</Wrapper>
    </AppInsightsErrorBoundary>
  );
};

export default ErrorBoundary;
