export default {
  authorization: "Genehmigung",
  cancel: "Abbrechen",
  delete: "Löschen",
  wear: "Abnutzung",
  submit: "Absenden",
  create: "Anlegen",
  asset: "Asset",
  dashboard: "Dashboard",
  details: "Details",
  alerts: "Alerts",
  description: "Beschreibung",
  confirmationOfOrder: "Auftragsbestätigung",
  feature: "Ausstattungsmerkmal",
  equipment: "Ausrüstung",
  select: "Auswählen",
  administrator: "Administrator",
  digger: "Bagger",
  yearOfConstruction: "Baujahr",
  confirm: "Bestätigen",
  edit: "Bearbeiten",
  event: "Ereignis",
  continue: "Fortsetzen",
  machine: "Gerät",
  media: "Media",
  deliveryNote: "Lieferschein",
  rentalAgreement: "Mietvertrag",
  tenant: "Mandant",
  topSlewingCranes: "Obendreher",
  OK: "OK",
  scheduler: "Planer",
  defaultValue: "Standardwert",
  productArea: "Produktbereich",
  productDivision: "Produktsparte",
  productGroup: "Produktgruppe",
  productLine: "Produktlinie",
  productFamily: "Produktfamilie",
  productModel: "Produktmodell",
  productCategory: "Produktkategorie",
  wheelLoader: "Radlader",
  invoice: "Rechnung",
  noImage: "Kein Bild",
  noDescription: "Keine Beschreibung",
  crawler: "Raupenbagger",
  quickChanger: "Schnellwechsler",
  weldingBadge: "Schweißplakette",
  save: "Speichern",
  search: "Suche",
  listOfParts: "Stückliste",
  towerPiece: "Turmstück",
  next: "Weiter",
  hotline: "Hotline",
  noHotlineAvailable: "Keine Hotline verfügbar",
  yes: "Ja",
  no: "Nein",
  back: "Zurück",
  assign: "Zuordnen",
  field: "Feld",
  key: "Schlüssel",
  value: "Wert",
  all: "Alle",
  user: "Benutzer",
  paired: "Verbundende",
  notPaired: "Nicht Verbundende",
  receiver: "Receiver",
  list: "List",
  rest: "Rest",
  Reset: "Reset",
  interval: "Interval",
  state: "State",
  coupling: "Coupling",
  never: "never",
  optional: "Optional",
  good: "Good",
  critical: "Critical",
  unknown: "Unknown",
  imageFormat: "SVG, PNG, JPG or GIF (max. 3MB)",
  pdfFormat: "PDF (max. 3MB)",
  template: "Vorlage",
  column: "Spalte",
  row: "Zeile",
  resetPassword: "Passwort zurücksetzen",
};
