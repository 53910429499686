export default {
  label: "Software Management",
  tabs: {
    myEdgeDevices: {
      label: "My Edge Devices",
      lastConnection: "Last Connection",
      upDateStatus: "Update Status",
      sshSession: "SSH Session",
      appSetSubscriptions: "App Set Subscriptions ",
      activities: "Activities",
    },
    appSets: {
      label: "App Sets",
      appSet: "App Set",
      newAppset: "New App Set",
      apps: "Apps",
      app: "App",
      appCategory: "App Category",
      deviceList: "Device List",
      activities: "Activities",
      appCategories: "App categories",
      developer: "Developer",
      version: "Version",
      releaseDate: "Release Date",
      size: "Size[MB]",
      revision: "Revision",
    },

    apps: {
      label: "Apps",
      addButton: "Add App",
      removeButton: "Remove App",
    },
    updatePropositions: {
      label: "Update Propositions",
    },
  },
};
