import { Button, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import projectColors from "themes/projectColors";
import translations from "translations";

const BoxSc = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  marginTop: "2rem",
}));
const TypographySC = styled(Typography)(({ theme }) => ({
  color: projectColors.functional.black,
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "1.5rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "133.4%",
}));
const ButtonSc = styled(Button)(({ theme }) => ({
  color: projectColors.functional.black,
  backgroundColor: projectColors.functional.white,
  fontFamily: "Roboto",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "133.4%",
  padding: theme.spacing(1.5, 3),
  "&:hover": {
    backgroundColor: projectColors.functional.black,
    color: projectColors.functional.white,
  },
}));
const ErrorElement: React.FC = () => {
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <BoxSc>
      <img style={{ width: "19rem" }} src="/images/errorImage.gif" alt="errorImage" />
      <TypographySC>{translations.globals.notifications.errorPage}</TypographySC>
      <ButtonSc variant="contained" color="primary" onClick={handleRefresh}>
        {translations.globals.button.tryAgain}
      </ButtonSc>
    </BoxSc>
  );
};
export default ErrorElement;
