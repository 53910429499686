export default {
  label: "Nicht gefunden",
  noPageFound: {
    title: "Nicht gefunden (404)",
    text: "Die Seite, nach der Sie gesucht haben, existiert nicht. Vielleicht haben Sie sich bei der Adresse vertippt oder die Seite ist umgezogen. ",
  },
  restrictedLink: {
    title: "Dieser Link ist eingeschränkt. ",
    text: "Für weitere Informationen wenden Sie sich bitte an Ihren Systemadministrator. ",
  },
  goBackLink: "Zurück zur Startseite. ",
};
