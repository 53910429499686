import * as React from "react";
import { SVGProps } from "react";
const LogoFGWSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg width={43} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.71 19.706v-.671H2.604v6.494h.76v-3.221h2.274v-.672H3.365v-1.93H5.71Zm4.728 2.559v.671h2.029c0 1.206-1.25 2.033-2.436 2.033-.716 0-1.44-.354-1.939-.827a2.554 2.554 0 0 1-.796-1.834c0-1.465 1.231-2.713 2.79-2.713 1.005 0 1.774.456 2.345 1.223l.552-.492c-.724-.895-1.693-1.404-2.89-1.404-1.964 0-3.558 1.517-3.558 3.377 0 1.826 1.594 3.342 3.514 3.342.95 0 1.902-.396 2.527-1.085.625-.69.724-1.413.707-2.291h-2.845Zm4.176-3.23h-.825l2.618 6.83 2.21-5.228 2.21 5.227 2.618-6.83h-.824l-1.794 4.867-2.21-5.202-2.21 5.202-1.793-4.866Z"
      fill="#005B7E"
    />
    <path
      d="M21.114 13.072a21.692 21.692 0 0 0-5.824-.818c-.735 0-1.462.042-2.181.122l-1.28 1.549a16.272 16.272 0 0 1 6.444.39c5.885 1.564 9.806 5.85 11.496 11.151h5.358c-3.122-6.167-8.678-10.906-14.013-12.394Z"
      fill="#005B7E"
    />
    <path
      d="M33.823 16.7c-2.79-2.48-5.213-4.355-11.02-5.913a22.494 22.494 0 0 0-5.822-.786c-.67 0-1.333.035-1.99.099l-1.256 1.52a20.53 20.53 0 0 1 1.555-.061c1.981 0 4.004.283 6.012.845 4.289 1.195 8.598 4.322 11.825 8.579a27.283 27.283 0 0 1 2.764 4.482h5.698a61.25 61.25 0 0 0-.85-1.142l-3.18-3.85c-1.375-1.534-2.677-2.831-3.736-3.773Z"
      fill="#568498"
    />
    <path
      d="M22.984 10.117c5.243 1.406 7.877 3.136 10.327 5.214L21.993 1.633l-6.384 7.72c.454-.029.911-.046 1.372-.046 1.98 0 4 .272 6.003.81Z"
      fill="#96B4C3"
    />
  </svg>
);
export default LogoFGWSmall;
