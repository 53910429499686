// import { ScopeEntry } from "authorization/types";
import { useMemo } from "react";
import { Route } from "./types";
import routes from "../routes";

interface WalkedRoute extends Route {
  depth: number;
}

type RouteCallback = (route: WalkedRoute) => Route | undefined;

export const walkRoutes = (callbacks: RouteCallback[]) => {
  const walk = ({ routes, depth = 0, scope = "Home" }: { routes: Route[]; depth: number; scope?: string }): Route[] => {
    return routes.reduce<Route[]>((result, route) => {
      const walkedRoute = callbacks.reduce<Route | undefined>((route, callback) => {
        if (!route) return route;
        return callback({ scope, ...route, depth });
      }, route);

      if (!walkedRoute) return result;
      return result.concat({
        ...walkedRoute,
        children: walk({ routes: walkedRoute.children ?? [], depth: depth + 1, scope: walkedRoute.scope ?? scope }),
      });
    }, []);
  };
  return walk;
};

export const useWalkedRoutes = (callbacks: RouteCallback[]) => {
  const walk = useMemo(() => walkRoutes(callbacks), [callbacks]);
  return walk({ routes: routes(true), depth: 0 });
};
