export default {
  label: "Alerts and Notifications",
  alerts: "Alerts",
  alertDefinitions: "Alert Management",
  scopeEdit: {
    operator: "Operator",
    value: "Value",
    WarningScope: "Warning Scope",
  },
  NotificationResubmissionInterval: {
    h1: "1 h",
    h6: "6 h",
    h12: "12 h",
    h24: "24 h",
    never: "Never",
  },
  createAlert: "Create Alert",
};
